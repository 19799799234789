import React from "react"
import { Link } from "gatsby"

const Navbar = ({ siteTitle, children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar.Top siteTitle={siteTitle} />
      <main className="font-mono flex-grow w-full sm:w-1/2 md:w-3/4 max-w-3xl mx-auto">
        {children}
      </main>
      <Navbar.Bottom />
    </div>
  )
}

Navbar.Top = ({ siteTitle }) => {
  return (
    <nav className="font-mono w-full border-2 border-l-0 border-r-0 border-t-0 border-black p-2 mb-4">
      <Link to="/">{siteTitle}</Link>
    </nav>
  )
}

Navbar.Bottom = () => {
  return (
    <nav className="font-mono flex flex-row-reverse w-full border-2 border-l-0 border-r-0 border-b-0 border-black p-2 mt-4">
      <Link className="p-5" to="/">
        /
      </Link>
    </nav>
  )
}

export default Navbar
